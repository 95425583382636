.conversation-item {
  cursor: pointer;
  transition-duration: 150ms;
  min-height: 5em;
}

.conversation-item:hover {
  transform: scale(1.04, 1);
  background-color: #e6e6e6;
  transition-duration: 250ms;
}

.message-list {
  width: 100%;
}

.message-list:hover .conversation-item {
  opacity: 0.9;
  transition-duration: 250ms;
}
.message-list:hover .conversation-item:hover {
  opacity: 1;
  transition-duration: 250ms;
}

.unread span {
  font-weight: bold;
  text-decoration: underline;
}

.no-conversations-label {
  display: flex;
  justify-content: center;
  font-size: 1.3em;
  color: #777;
  margin-top: 2em;
}
