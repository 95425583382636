@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

html {
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

body {
  display: flex;
  flex-direction: column;
  color: #333;
  background-color: #fff;
  margin-left: calc(100vw - 100%);
  margin-right: 0;
  width: 100vw;
  overflow-x: hidden;
}
body ::selection {
  background: #3e3dae;
  color: #fff;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Phone portrait */
@media only screen and (max-device-width: 1280px) {
  body {
    padding: 2em 0 0 0;
  }
  .main-container {
    width: 92%;
  }
}

/* Laptop Screens */
@media screen and (min-device-width: 1280px) {
  body {
    padding: 108px 0 0 0;
  }
  .main-container {
    width: 80%;
  }
}

.main-container {
  display: flex;
  justify-content: center;
  max-width: 1440px;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}

.label-1 {
  font-size: 0.7em;
}
.label-2 {
  font-size: 0.8em;
}
.label-3 {
  font-size: 0.9em;
}
.label-4 {
  font-size: 1.15em;
}
.label-5 {
  font-size: 1.3em;
}
.label-6 {
  font-size: 1.45em;
}
.label-7 {
  font-size: 1.55em;
}
